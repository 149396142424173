//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-656:_4632,_4948,_1960,_2932,_7517,_3576,_5116,_104;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-656')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-656', "_4632,_4948,_1960,_2932,_7517,_3576,_5116,_104");
        }
      }catch (ex) {
        console.error(ex);
      }