//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-656:_6724,_8833,_9972,_7508,_6284,_8976,_8152,_5984;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-656')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-656', "_6724,_8833,_9972,_7508,_6284,_8976,_8152,_5984");
        }
      }catch (ex) {
        console.error(ex);
      }